<template>
  <div>
    <!-- Filter -->
    <div class="row justify-content-end mt-n3">
      <div class="col-md-3">
        <b-input-group>
          <template #prepend>
            <b-input-group-text squared>
              <b-icon-search></b-icon-search>
            </b-input-group-text>
          </template>
          <b-form-input
            type="text"
            v-model="filter.name"
            placeholder="Cari Nama"
            @keyup="filterByName"
          ></b-form-input>
        </b-input-group>
      </div>
    </div>

    <!-- Table -->
    <b-table
      striped
      hover
      class="mt-3"
      :items="items"
      :fields="fields"
      :style="'white-space: nowrap'"
      responsive="sm"
    >
      <template #table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="{ width: field.key === 'actions' ? '20%' : '' }"
        />
      </template>
      <template #cell(range_male)="data">
        <span>{{
          data.item.start_range_male +
          " - " +
          data.item.end_range_male +
          " " +
          data.item.uom
        }}</span>
      </template>
      <template #cell(range_female)="data">
        <span>{{
          data.item.start_range_female +
          " - " +
          data.item.end_range_female +
          " " +
          data.item.uom
        }}</span>
      </template>

      <template #cell(actions)="data">
        <b-button size="sm" class="mr-1 btn-info" @click="chosenProfession(data.item)">Pilih</b-button>
      </template>
    </b-table>
    <b-pagination
      v-if="items.length != 0"
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      @page-click="pageOnClick"
      class="mt-4"
    ></b-pagination>
  </div>
</template>

<script>
import Card from "@/view/content/Card.vue";
import module from "@/core/modules/CrudModule.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "List",

  components: {
    Card,
  },

  data() {
    return {
      // Filter
      filter: {
        name: "",
      },
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      // Table Head
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "name",
          label: "Nama",
          sortable: true,
        },
        {
          key: "description",
          label: "Deskripsi",
          sortable: true,
        },
        { key: "actions", label: "Aksi" },
      ],
      // Table Items
      items: [],
      // user access
      btn: true,
    };
  },

  methods: {
    pageOnClick(evt, page) {
      evt.preventDefault();
      this.currentPage = page;
      this.pagination();
    },

    async pagination() {
      let filterParams = `&name=${this.filter.name}`;
      let response = await module.paginate(
        "profession",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }

      this.items = response.data;
    },

    filterByName() {
      console.log("filter jalan");
      this.pagination();
    },

    async deleteData(id) {
      // Delete Data
      let result = await module.delete("profession/" + id);
      // If Deleted
      if (result) {
        this.pagination();
      }
    },

    chosenProfession(value) {
      this.$emit("chosenProfession", value);
    },

    setActiveButton() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);

      let a;
      for (a = 0; a < access_right.length; a++) {
        if (access_right[a] == "1107") {
          this.btn = true;
        }
      }
    },
  },

  mounted() {
    // BreadCrumb
    // Get Data
    this.pagination();
  },
};
</script>
