<template>
    <div>

      <b-modal
        id="modal-profession"
        size="xl"
        title="Pilih Pekerjaan"
        hide-footer
      >
        <!-- Tabs -->
        <b-tabs>
          <b-tab
            title="Daftar Pekerjaan"
            active
          >
            <div class="mt-5">
              <Table
                purpose="modal"
                @chosenProfession="chosenProfession"
              />
            </div>
          </b-tab>
          <b-tab title="Tambah Baru">
            <div
              class="mt-4"
            >
              <Form
                :form="form"
                :route="'profession'"
                summonPurpose="modal"
                @submitedProfession="submitedProfession"
              />
            </div>
          </b-tab>
        </b-tabs>
      </b-modal>

    </div>
</template>

<script>

import Form from '@/view/components/profession/Form.vue'
import Table from '@/view/components/profession/Table.vue'

export default {

  components: {
    Form,
    Table
  },

  methods: {

    chosenProfession(value) {
      this.$emit('chosenProfession', value)
    },

    submitedProfession(value) {
      this.$emit('submitedProfession', value)
    },

  },

  data() {
    return {
      form: {
        name: "",
        description: "",
      }
    }
  }

}
</script>

<style>
</style>