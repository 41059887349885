<template>
  <v-app>
    <div>
      <form class="form" @submit.stop.prevent="formOnsubmit()">
        <!--begin: Wizard Step 1-->
        <div
          class="pb-5"
          data-wizard-type="step-content"
          data-wizard-state="current"
        >
          <h5 class="font-weight-bolder text-dark font-size-h6 font-size-h3-lg">
            {{ title }}
          </h5>

          <div class="row">
            <div class="col">
              <b-form-group
                id="input-group-family-number"
                label="Nomor Kartu Keluarga:"
                label-for="input-family-number"
              >
                <b-form-input
                  id="input-family-number"
                  v-model="form.family_number"
                  placeholder="Nomor Kartu Keluarga"
                ></b-form-input>
                <small class="text-danger">{{ error.family_number }}</small>
              </b-form-group>
            </div>
            <div class="col">
              <b-form-group
                id="input-group-id-card-number"
                label="Nomor Induk Kependudukan:"
                label-for="input-id-card-number"
              >
                <b-form-input
                  id="input-id-card-number"
                  v-model="form.id_card_number"
                  placeholder="Nomor Induk Kependudukan"
                ></b-form-input>
                <small class="text-danger">{{ error.id_card_number }}</small>
              </b-form-group>
            </div>
          </div>

          <b-form-group
            id="input-group-name"
            label="Nama:"
            label-for="input-name"
          >
            <b-form-input
              id="input-name"
              v-model="form.name"
              placeholder="Nama"
            ></b-form-input>
            <small class="text-danger">{{ error.name }}</small>
          </b-form-group>

          <b-form-group
            id="input-group-birth-date"
            label="Tempat & Tanggal Lahir:"
            label-for="input-sip-birth-date"
          >
            <b-input-group>
              <div class="row">
                <div class="col-md-6">
                  <b-form-input
                    id="input-birth-place"
                    aria-label="Tempat Lahir"
                    placeholder="Tempat Lahir (opsional)"
                    v-model="form.birt_place"
                  ></b-form-input>
                </div>
                <div class="col-md-6">
                  <v-dialog
                    ref="dialog"
                    v-model="modal2"
                    :return-value.sync="form.birt_date"
                    persistent
                    width="350px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <b-input-group>
                        <b-input-group-prepend>
                          <button
                            class="btn btn-secondary"
                            type="button"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <i class="fas fa-calendar"></i>
                          </button>
                        </b-input-group-prepend>
                        <b-form-input
                          id="input-trigger-modal-patient"
                          v-model="form.birt_date"
                          placeholder="Tanggal Lahir (YYYY-MM-DD)"
                          readonly
                        >
                        </b-form-input>
                      </b-input-group>
                    </template>

                    <v-date-picker
                      v-if="modal2"
                      v-model="form.birt_date"
                      locale="id"
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="modal2 = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dialog.save(form.birt_date)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </div>
              </div>
            </b-input-group>
            <div class="row">
              <div class="col">
                <small class="text-danger">{{ error.birt_place }}</small>
              </div>
              <div class="col">
                <small class="text-danger">{{ error.birt_date }}</small>
              </div>
            </div>
          </b-form-group>

          <div class="row">
            <div class="col-2">
              <b-form-group label="Jenis Kelamin:" v-slot="{ ariaDescribedby }">
                <b-form-radio
                  v-model="form.gender"
                  :aria-describedby="ariaDescribedby"
                  name="some-radios"
                  value="male"
                  >Laki-laki</b-form-radio
                >
                <b-form-radio
                  v-model="form.gender"
                  :aria-describedby="ariaDescribedby"
                  name="some-radios"
                  value="female"
                  >Perempuan</b-form-radio
                >
                <small class="text-danger">{{ error.gender }}</small>
              </b-form-group>
            </div>
            <div class="col">
              <b-form-group id="input-group-blood_type">
                <label for="input-blood_type"
                  >Golongan Darah: <em class="text-muted">Opsional</em></label
                >
                <!-- <b-form-input
                  id="input-blood_type"
                  v-model="form.blood_type"
                  placeholder="Golongan Darah"
                ></b-form-input> -->
                <b-form-select
                  v-model="form.blood_type"
                  :options="blood_types"
                ></b-form-select>
                <small class="text-danger">{{ error.blood_type }}</small>
              </b-form-group>
            </div>
          </div>

          <b-form-group
            id="input-group-citizenship"
            label="Kewarganegaraan:"
            label-for="input-citizenship"
          >
            <b-form-input
              id="input-citizenship"
              v-model="form.citizenship"
              placeholder="Kewarganegaraan"
            ></b-form-input>
            <small class="text-danger">{{ error.citizenship }}</small>
          </b-form-group>

          <b-form-group
            id="input-group-religion"
            label="Agama:"
            label-for="input-religion"
          >
            <!-- <b-form-input
              id="input-religion"
              v-model="form.religion"
              placeholder="Agama"
            ></b-form-input> -->
            <b-form-select v-model="form.religion" class="mb-3">
              <b-form-select-option value="Islam">Islam</b-form-select-option>
              <b-form-select-option value="Protestan"
                >Protestan</b-form-select-option
              >
              <b-form-select-option value="Katolik"
                >Katolik</b-form-select-option
              >
              <b-form-select-option value="Hindu">Hindu</b-form-select-option>
              <b-form-select-option value="Buddha">Buddha</b-form-select-option>
              <b-form-select-option value="Khonghucu"
                >Khonghucu</b-form-select-option
              >
            </b-form-select>
            <small class="text-danger">{{ error.religion }}</small>
          </b-form-group>

          <div class="row">
            <div class="col">
              <b-form-group
                id="input-group-last_education"
                label="Pendidikan Terakhir:"
                label-for="input-last_education"
              >
                <b-form-input
                  id="input-last_education"
                  v-model="form.last_education"
                  placeholder="Pendidikan Terakhir"
                ></b-form-input>
                <small class="text-danger">{{ error.last_education }}</small>
              </b-form-group>
            </div>
            <div class="col">
              <!-- <b-form-group
                id="input-group-profession"
                label="Pekerjaan:"
                label-for="input-profession"
              >
                <b-form-input
                  id="input-profession"
                  v-model="form.profession"
                  placeholder="Pekerjaan"
                ></b-form-input>
                <small class="text-danger">{{ error.profession }}</small>
              </b-form-group> -->
              <b-form-group
                id="input-group-trigger-modal-profession"
                label="Pekerjaan:"
                label-for="input-trigger-modal-profession"
              >
                <b-form-input
                  id="input-trigger-modal-profession"
                  v-model="display.profession_name"
                  placeholder="Pilih Pekerjaan"
                  readonly
                  @click="$bvModal.show('modal-profession')"
                ></b-form-input>
                <small class="text-danger">{{ error.profession_id }}</small>
              </b-form-group>
            </div>
          </div>

          <b-form-group id="input-group-special_needs">
            <label for="input-special_needs"
              >Kebutuhan Khusus: <em class="text-muted">Opsional</em></label
            >
            <b-form-input
              id="input-special_needs"
              v-model="form.special_needs"
              placeholder="Kebutuhan Khusus"
            ></b-form-input>
            <small class="text-danger">{{ error.special_needs }}</small>
          </b-form-group>

          <div class="row">
            <div class="col">
              <b-form-group
                id="input-group-father_name"
                label="Nama Ayah:"
                label-for="input-father_name"
              >
                <b-form-input
                  id="input-father_name"
                  v-model="form.father_name"
                  placeholder="Nama Ayah"
                ></b-form-input>
                <small class="text-danger">{{ error.father_name }}</small>
              </b-form-group>
            </div>
            <div class="col">
              <b-form-group
                id="input-group-mother_name"
                label="Nama Ibu:"
                label-for="input-mother_name"
              >
                <b-form-input
                  id="input-mother_name"
                  v-model="form.mother_name"
                  placeholder="Nama Ibu"
                ></b-form-input>
                <small class="text-danger">{{ error.mother_name }}</small>
              </b-form-group>
            </div>
          </div>

          <!-- <b-form-group
              id="input-group-urban-village"
              label="Dusun:"
              label-for="input-urban-village"
            >
              <treeselect
                v-model="form.urban_village_id"
                :multiple="false"
                placeholder="Pilih Dusun"
                :options="urban_village"
              />
              <small class="text-danger">{{ error.urban_village_id }}</small>
            </b-form-group> -->

          <b-form-group
            id="input-group-hamlet_id"
            label="RW:"
            label-for="input-hamlet_id"
            label-class="label-bold"
          >
            <treeselect
              v-model="form.hamlet_id"
              :multiple="false"
              placeholder="Pilih RW"
              :options="hamlets"
            />
            <small class="text-danger">{{ error.hamlet_id }}</small>
          </b-form-group>

          <b-form-group id="input-group-address">
            <label for="input-address">Alamat:</label>
            <b-form-textarea
              id="input-address"
              v-model="form.address"
              placeholder="Alamat"
              rows="4"
              max-rows="8"
            ></b-form-textarea>
            <small class="text-danger">{{ error.address }}</small>
          </b-form-group>

          <fieldset
            style="border: 1px solid #dee2e6; padding: 8px"
            class="mb-3"
          >
            <legend
              style="
                border: 1px solid #dee2e6;
                padding: 9px;
                margin-left: 13px;
                font-size: initial;
                width: initial;
                background: white;
              "
            >
              Status
            </legend>
            <b-form-group label="" v-slot="{ ariaDescribedby }">
              <b-form-radio
                v-model="form.is_married"
                :aria-describedby="ariaDescribedby"
                name="some-radios-1"
                value="1"
                >Kawin</b-form-radio
              >
              <b-form-radio
                v-model="form.is_married"
                :aria-describedby="ariaDescribedby"
                name="some-radios-1"
                value="0"
                >Belum Kawin</b-form-radio
              >
              <small class="text-danger">{{ error.is_married }}</small>
            </b-form-group>

            <b-form-group label="" v-slot="{ ariaDescribedby }">
              <b-form-radio
                v-model="form.status_in_family"
                :aria-describedby="ariaDescribedby"
                name="some-radios-family"
                value="1"
                >Kepala Keluarga</b-form-radio
              >
              <b-form-radio
                v-model="form.status_in_family"
                :aria-describedby="ariaDescribedby"
                name="some-radios-family"
                value="2"
                >Istri</b-form-radio
              >
              <b-form-radio
                v-model="form.status_in_family"
                :aria-describedby="ariaDescribedby"
                name="some-radios-family"
                value="3"
                >Anak</b-form-radio
              >
              <b-form-radio
                v-model="form.status_in_family"
                :aria-describedby="ariaDescribedby"
                name="some-radios-family"
                value="4"
                >Saudara</b-form-radio
              >
              <small class="text-danger">{{ error.status_in_family }}</small>
            </b-form-group>

            <b-form-group label="" v-slot="{ ariaDescribedby }">
              <b-form-radio
                v-model="form.is_move"
                :aria-describedby="ariaDescribedby"
                name="some-radios-2"
                value="0"
                >Penduduk Tetap</b-form-radio
              >
              <b-form-radio
                v-model="form.is_move"
                :aria-describedby="ariaDescribedby"
                name="some-radios-2"
                value="1"
                >Penduduk Pindahan</b-form-radio
              >
              <b-form-radio
                v-model="form.is_move"
                :aria-describedby="ariaDescribedby"
                name="some-radios-2"
                value="2"
                >Penduduk Sementara</b-form-radio
              >
              <small class="text-danger">{{ error.is_move }}</small>
            </b-form-group>

            <b-form-group label="" v-slot="{ ariaDescribedby }">
              <b-form-radio
                v-model="form.is_dead"
                :aria-describedby="ariaDescribedby"
                name="some-radios-3"
                value="0"
                >Masih Hidup</b-form-radio
              >
              <b-form-radio
                v-model="form.is_dead"
                :aria-describedby="ariaDescribedby"
                name="some-radios-3"
                value="1"
                >Meninggal</b-form-radio
              >
              <small class="text-danger">{{ error.is_dead }}</small>
            </b-form-group>

            <b-form-group label="" v-slot="{ ariaDescribedby }">
              <b-form-radio
                v-model="form.is_blt"
                :aria-describedby="ariaDescribedby"
                name="some-radios-1"
                value="1"
                >Penerima BLT</b-form-radio
              >
              <b-form-radio
                v-model="form.is_blt"
                :aria-describedby="ariaDescribedby"
                name="some-radios-1"
                value="0"
                >Bukan Penerima BLT</b-form-radio
              >
              <small class="text-danger">{{ error.is_blt }}</small>
            </b-form-group>
          </fieldset>
        </div>
        <!--end: Wizard Step 1-->

        <!--begin: Wizard Actions -->
        <div class="d-flex justify-content-between border-top pt-10">
          <div>
            <b-button type="submit" variant="primary">Simpan</b-button>
            <b-button
              type="button"
              class="ml-2"
              variant="default"
              @click="$router.push('/citizen/list')"
            >
              Batal
            </b-button>
          </div>
        </div>
        <!--end: Wizard Actions -->
      </form>
      <ModalProfession
        @chosenProfession="setProfession"
        @submitedProfession="setProfession"
      />
    </div>
  </v-app>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import ModalProfession from "@/view/components/general/ModalProfession.vue";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  props: {
    title: String,
    form: Object,
    route: String,
    purpose: {
      type: String,
      default: "add",
    },
  },
  components: {
    ModalProfession,
  },
  data() {
    return {
      error: {
        family_number: "",
        id_card_number: "",
        name: "",
        gender: "",
        citizenship: "",
        birt_place: "",
        birt_date: "",
        blood_type: "",
        last_education: "",
        profession: "",
        special_needs: "",
        father_name: "",
        mother_name: "",
        is_dead: "",
        is_move: "",
        address: "",
        status_in_family: "",
        hamlet_id: "",
      },
      display: {
        profession_name: "",
      },
      blood_types: [
        { value: "", text: "Pilih Golongan Darah" },
        { value: "A", text: "A" },
        { value: "AB", text: "AB" },
        { value: "O", text: "O" },
        { value: "B", text: "B" },
      ],
      urban_village: [],
      hamlets: [],
      modal2: false,
    };
  },
  methods: {
    async getUrbanVillageOption() {
      let response = await module.setTreeSelect("urban-village");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.urban_village = response.data;
        this.urban_village.unshift({
          label: "Pilih Dusun",
          id: "",
          isDisabled: true,
        });
      }
    },
    async getHamletHeadOption() {
      let response = await module.setTreeSelectHamletHead("hamlets");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.hamlets = response.data;
        this.hamlets.unshift({
          label: "Pilih No. WA Ketua RW",
          id: "",
          isDisabled: true,
        });
      }
    },
    setProfession(value) {
      this.form.profession_id = value.id;
      this.display.profession_name = value.name;
      this.$bvModal.hide("modal-profession");
    },
    async formOnsubmit() {
      // Make Request
      let response = await module.submit(this.form, this.route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.$router.push("/citizen/list");
      }
    },
  },
  mounted() {
    this.getUrbanVillageOption();
    this.getHamletHeadOption();
  },
  watch: {
    form: function (newVal, oldVal) {
      if (this.purpose == "edit") {
        this.display.profession_name = this.form.profession_name;
      }
    },
  },
};
</script>

<style scoped>
</style>